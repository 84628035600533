/*------------- #W-SUBSCRIBE --------------*/


.widget.w-subscribe {
  background-color: $primary-color;
  background-image: url(../img/bg-help.png);

  .title {
	font-weight: 400;
	color: #15171c;
	margin: 0 0 30px 0;
  }
}