/*------------- #WIDGETS --------------*/

@import "theme-styles/variables";

@import "widgets/w-info";
@import "widgets/w-help";
@import "widgets/w-tags";
@import "widgets/w-latest-news";
@import "widgets/w-subscribe";
@import "widgets/w-upcoming-events";
@import "widgets/w-archives";
@import "widgets/w-distribution-ends";
